// Libraries
import React from "react";
import { useOvermind } from "@overmind/index";

// UI Components
import Container from "@material-ui/core/Container";
import Layout from "@components/layout";
import ProjectCard from "@components/basics/card";

const ProjectsPage: React.FC = () => {
  const {
    state: {
      project: { list, status },
    },
  } = useOvermind();

  return (
    <Layout loading={status.list === "loading"} loadingType="list" title="Projects">
      <Container>
        {list
          .filter((project) => !project.hidden)
          .map((project) => (
            <ProjectCard key={project.slug} item={project} />
          ))}
      </Container>
    </Layout>
  );
};

export default ProjectsPage;
