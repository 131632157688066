import firebase from "firebase/app";

export default (() => {
  let database: firebase.database.Database;

  return {
    initialize() {
      database = firebase.database();
    },
    getKey() {
      return database.ref("pages").push().key;
    },
  };
})();
