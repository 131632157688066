// Libraries
import React from "react";

// Global
import { LINKEDIN } from "@global/index";

// UI Components
import Box from "@material-ui/core/Box";
import Button from "@components/basics/button";
import Container from "@material-ui/core/Container";
import Layout from "@components/layout";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./Contact.style";

const ContactPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout title="Contact" contact={false}>
      <Container>
        <Box className={classes.root}>
          <Typography color="textSecondary" className={classes.info} variant="h5" component="div">
            If you have any questions, please don't hesitate to reach out!
          </Typography>
          <Button color="secondary" href={LINKEDIN} external size="large">
            Say Hello
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default ContactPage;
