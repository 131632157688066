// Libraries
import React, { useEffect } from "react";

// Hooks
import { useOvermind } from "@overmind/index";
import { Redirect, useParams } from "react-router";

// Types
import { ProjectItem } from "@overmind/namespaces/project/types";

// UI Components
import Box from "@material-ui/core/Box";
import Button from "@components/basics/button";
import Container from "@material-ui/core/Container";
import Content from "@components/basics/content";
import Layout from "@components/layout";
import LazyImage from "@components/basics/lazy-image";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./ProjectDetail.styles";

const ProjectDetailPage: React.FC = () => {
  const params = useParams<{ slug: string }>();
  const classes = useStyles();
  const {
    actions: {
      project: { getOne },
    },
    state: {
      project: { detail, list, status },
    },
  } = useOvermind();
  const loading = status.detail === "loading";
  const { content, fields, hidden, images = [], tags, title } = detail ?? {};
  const [featured, ...contentImages] = images;
  const isAvailable = Boolean(!hidden && title);

  useEffect(() => {
    if (list.length > 0) {
      getOne(params.slug);
    }
  }, [list]);

  return (
    <Layout loading={loading} title={title} img={featured?.url} size="medium">
      {!isAvailable && <Redirect to="/404" />}
      <Container>
        <Content content={content} />
        {fields && (
          <>
            <Typography variant="h5" className={classes.subtitle}>
              Expertise
            </Typography>
            <Box className={classes.tags}>
              {fields.split(",").map((field) => (
                <Button key={field} size="small" color="secondary" type="button">
                  {field}
                </Button>
              ))}
            </Box>
          </>
        )}
        {tags && (
          <>
            <Typography variant="h5" className={classes.subtitle}>
              Tech Stack
            </Typography>
            <Box className={classes.tags}>
              {tags.split(",").map((tag) => (
                <Button key={tag} size="small" color="secondary" type="button">
                  {tag}
                </Button>
              ))}
            </Box>
          </>
        )}
        {(contentImages ?? []).map(({ key, url }, index) => (
          <LazyImage key={`img-${key}`} src={url} alt={`${title} image ${index}`} className={classes.img} />
        ))}
      </Container>
    </Layout>
  );
};

export default ProjectDetailPage;
