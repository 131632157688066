// Libraries
import React from "react";

// UI Components
import Button from "@components/basics/button";
import Container from "@material-ui/core/Container";
import Layout from "@components/layout";

const NotFoundPage: React.FC = () => (
  <Layout title="404">
    <Container>
      <Button color="secondary" href="/" size="large">
        Go Home Page
      </Button>
    </Container>
  </Layout>
);

export default NotFoundPage;
