import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    "@keyframes tailAnim": {
      "0%": {
        transform: "rotate(0)",
      },
      "50%": {
        transform: "rotate(10deg)",
      },
      "100%": {
        transform: "rotate(50deg)",
      },
    },
    "@keyframes catAnim": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.3,
      },
      "100%": {
        opacity: 1,
      },
    },
    animationCat: {
      position: "relative",
      width: 100,
      zIndex: 2,
    },
    animationLoading: {
      position: "absolute",
      top: 32,
      left: 72,
      color: theme.palette.common.white,
    },
    animationTail: {
      position: "absolute",
      top: -14,
      right: -27,
      width: 35,
      zIndex: 1,
      animation: "1s $tailAnim infinite",
      transformOrigin: "left",
      transform: "rotate(50deg)",
      animationTimingFunction: "step-end",
    },
    "@keyframes barAnim": {
      "0%": {
        opacity: 1,
        transform: "translateX(-10%)",
      },
      "100%": {
        opacity: 0,
        transform: "translateX(110%)",
      },
    },
    bar: {
      animation: "$barAnim 1s ease-in-out infinite",
      position: "absolute",
      left: 0,
      top: 0,
      height: 3,
      width: "100%",
      transform: "translateX(-10%)",
      background: theme.palette.common.white,
    },
    container: {
      position: "absolute",
      left: 5,
      bottom: -7,
      animation: "$catAnim 1s linear infinite",
    },
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      position: "absolute",
      width: "100%",
      background: theme.palette.primary.main,
      left: 0,
      top: 0,
      zIndex: 999999,
      overflow: "hidden",
      transition: "all 500ms",
    },
    rootAnimated: {
      opacity: 0,
      visibility: "hidden",
    },
  }),
  { name: "Pa3geLoading" }
);
