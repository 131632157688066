// Libraries
import React, { useState } from "react";
import clsx from "clsx";

// Components
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "./LazyImage.style";

type Props = {
  alt: string;
  className?: string;
  src: string;
};

const LazyImage: React.FC<Props> = ({ alt, className, src }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  return (
    <Box className={className}>
      {loading && <Skeleton animation="wave" variant="rect" className={classes.skeleton} />}
      <img
        className={clsx(classes.image, loading && classes.imageLoading)}
        alt={alt}
        src={src}
        onLoad={() => setLoading(false)}
      />
    </Box>
  );
};

export default LazyImage;
