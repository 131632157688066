// Libraries
import React from "react";

type Props = {
  title?: string;
  description?: string;
};

const Meta: React.FC<Props> = ({ title }) => {
  React.useEffect(() => {
    window.document.title = `${title} - Atilla Sertkaya`;
  }, [title]);

  return <></>;
};

export default Meta;
