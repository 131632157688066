import { IConfig } from "overmind";
import { createHook, createStateHook, createActionsHook, createEffectsHook, createReactionHook } from "overmind-react";
import { namespaced, merge } from "overmind/config";
import * as global from "./namespaces/global";
import * as auth from "./namespaces/auth";
import * as project from "./namespaces/project";
import * as page from "./namespaces/page";
import * as effects from "./effects";
import { onInitialize } from "./onInitialize";

export const config = merge(
  namespaced({
    auth,
    global,
    page,
    project,
  }),
  {
    effects,
    onInitialize,
  }
);

declare module "overmind" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Config
    extends IConfig<{
      state: typeof config.state;
      actions: typeof config.actions;
      effects: typeof config.effects;
    }> {}
  // Due to circular typing we have to define an
  // explicit typing of state, actions and effects since
  // TS 3.9
}

export const useOvermind = createHook<typeof config>();
export const useOState = createStateHook<typeof config>();
export const useOActions = createActionsHook<typeof config>();
export const useOEffects = createEffectsHook<typeof config>();
export const useOReaction = createReactionHook<typeof config>();
