// Libraries
import React, { useEffect } from "react";
import { useOvermind } from "@overmind/index";
import { Redirect, useParams } from "react-router";

// UI Components
import Container from "@material-ui/core/Container";
import Content from "@components/basics/content";
import Layout from "@components/layout";

const Page: React.FC = () => {
  const params = useParams<{ slug: string }>();
  const {
    actions: {
      page: { get },
    },
    state: {
      page: { detail, loading },
    },
  } = useOvermind();

  useEffect(() => {
    get(params.slug);
  }, []);
  const isAvailable = Boolean(detail?.title);

  return (
    <Layout loading={loading} title={detail?.title} size="medium">
      {!isAvailable && <Redirect to="/404" />}
      <Container>
        <Content content={detail?.content} />
      </Container>
    </Layout>
  );
};

export default Page;
