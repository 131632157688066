// Libraries
import React from "react";

// Global
import { LINKEDIN } from "@global/index";

// UI Components
import Button from "@components/basics/button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./Contact.style";

const Contact: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.container}>
      <Container className={classes.wrapper}>
        <Typography className={classes.text} color="primary" variant="h3">
          Do you have any questions?
        </Typography>
        <Button href={LINKEDIN} size="large" external>
          Contact Me
        </Button>
      </Container>
    </footer>
  );
};

export default Contact;
