import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: "100%",
      background: theme.palette.primary.main,
      zIndex: 100,
      height: "100vh",
      padding: 0,
      transition: "all 0.8s",
      overflow: "auto",
      position: "relative",
    },
  }),
  { name: "Frame" }
);
