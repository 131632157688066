// Libraries
import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

// UI Components
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from "./Button.style";

type Props = {
  color?: "primary" | "secondary";
  external?: boolean;
  fullWidth?: boolean;
  href?: string;
  loading?: boolean;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  type?: "submit" | "button";
};

const Button: React.FC<Props> = ({
  color = "primary",
  children,
  external,
  fullWidth,
  href,
  loading,
  size = "medium",
  onClick,
  type,
}) => {
  const classes = useStyles();
  const className = clsx(
    classes.container,
    fullWidth && classes.fullWidth,
    loading && classes.loading,
    classes[color],
    classes[size]
  );

  if (onClick || type) {
    return (
      <button
        onClick={onClick}
        type={type === "submit" ? "submit" : "button"}
        className={className}
        data-text={children}
      >
        {loading && (
          <Box className={classes.loadingIcon}>
            <CircularProgress color="secondary" size={20} />
          </Box>
        )}
        <span>{children}</span>
      </button>
    );
  }

  if (!external) {
    return (
      <Link className={className} to={href} data-text={children}>
        <span>{children}</span>
      </Link>
    );
  }

  return (
    <a className={className} href={href} target="_blank" data-text={children} rel="noreferrer">
      <span>{children}</span>
    </a>
  );
};

export default Button;
