// Libraries
import React from "react";

// UI Components
import Container from "@material-ui/core/Container";
import AnimatedLetters from "@components/basics/animated-letters";
import Layout from "@components/layout";
import { useStyles } from "./Skills.style";

const skills = [
  "UI/UX",
  "Docker, AWS S3, Kubernetes",
  "Html",
  "Css",
  "Sass/Less/Emotion",
  "Webpack",
  "Next JS",
  "Express",
  "Gulp/Webpack",
  "Javascript",
  "JQuery",
  "React JS",
  "React Native",
  "Redux/Overmind",
  "Angularjs",
  "Angular 5+",
  "Jest",
  "Enzyme/Testing Library",
  "Cypress",
  "C#",
  "Firebase",
  "NodeJS",
  "Typescript",
  "ES5/ES6",
  "Php",
  "Wordpress",
  "Sketch/Figma/Invision",
  "VS Code",
  "Storybook",
];

const SkillsPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout title="Skills">
      <Container>
        <div className={classes.container}>
          {skills.map((skill) => (
            <div className={classes.item} key={`skill-${skill}`}>
              <span className={classes.itemText}>
                <AnimatedLetters text={skill} />
              </span>
              <div className={classes.itemBar}>
                <div style={{ width: `${Math.floor(Math.random() * (100 - 80 + 1) + 80)}%` }} />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default SkillsPage;
