// Libraries
import { useOvermind } from "@overmind/index";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { adminLinks } from "@global/routes";

// UI Libraries
import { CircularProgress } from "@material-ui/core";

type Props = {
  exact?: boolean;
  path?: string;
  component?: any;
};

const PrivateRoute: React.FC<Props> = ({ component: Component, exact, path }) => {
  const { state } = useOvermind();

  if (state.auth.logging) {
    return <CircularProgress />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (state.auth.logged) {
          return <Route exact path={path} component={Component} />;
        }

        return <Redirect to={{ pathname: adminLinks.login }} />;
      }}
    />
  );
};

export default PrivateRoute;
