import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "fixed",
      left: 0,
      top: 0,
      width: 50,
      height: 50,
      borderRadius: "100%",
      overflow: "hidden",
      zIndex: 99999999,
      border: `2px solid ${theme.palette.primary.main}`,
      transition: "all 150ms",
      pointerEvents: "none",
      opacity: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        border: `4px solid ${theme.palette.secondary.main}`,
        display: "block",
        borderRadius: "100%",
      },
      "&:after": {
        content: "''",
        position: "absolute",
        left: 4,
        top: 4,
        width: "calc(100% - 8px)",
        height: "calc(100% - 8px)",
        border: `2px solid ${theme.palette.primary.main}`,
        display: "block",
        borderRadius: "100%",
      },
    },
  }),
  { name: "MousePointer" }
);
