// Libraries
import React from "react";

// UI Components
import Box from "@material-ui/core/Box";
import { useStyles } from "./Content.style";

type Props = {
  content: string;
};

const Content: React.FC<Props> = ({ content }) => {
  const classes = useStyles();

  if (!content) {
    return null;
  }

  return (
    <div className={classes.content}>
      <Box dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Content;
