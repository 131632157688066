// Libraries
import React from "react";
import { appLinks } from "@global/routes";

// Hooks
import { useOvermind } from "@overmind/index";
import { Link } from "react-router-dom";

// Icons
import PetsIcon from "@material-ui/icons/Info";

// Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Skeleton from "@components/basics/skeleton";
import Consent from "./components/consent";
import Contact from "./components/contact";
import Header from "./components/header";
import Footer from "./components/footer";
import Meta from "./components/meta";
import PageLoading from "./components/page-loading";
import { useStyles } from "./Layout.style";

type Props = {
  contact?: boolean;
  hideHeader?: boolean;
  img?: string;
  loading?: boolean;
  loadingType?: "content" | "list";
  size?: "medium" | "large";
  title?: string;
};

const DefaultLayout: React.FC<Props> = ({
  children,
  contact = true,
  hideHeader,
  img,
  loading,
  loadingType,
  size,
  title,
}) => {
  const classes = useStyles();
  const {
    state: {
      global: { appLoading },
    },
  } = useOvermind();

  return (
    <Box>
      <PageLoading />
      {!appLoading && (
        <>
          <Meta title={title} />
          {!hideHeader && <Header title={title} img={img} size={size} />}
          {loading && (
            <Container>
              <Skeleton type={loadingType} active />
            </Container>
          )}
          {!loading && children}
          {contact && <Contact />}
          {!hideHeader && <Footer />}
          <Consent />
        </>
      )}
      <Link to={appLinks.placeholder} className={classes.newsIndicator}>
        <PetsIcon className={classes.newsIndicatorIcon} />
        <Box className={classes.newsIndicatorText}>Try new Placeholder tool</Box>
      </Link>
    </Box>
  );
};

export default DefaultLayout;
