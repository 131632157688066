import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      "& > span": {
        cursor: "pointer",
        transition: "transform 300ms cubic-bezier(0.550, 0.085, 0.680, 0.530)",
        display: "inline-block",
        "&:hover": {
          color: theme.palette.secondary.main,
          transform: "translateY(-10px)",
        },
        "&:empty:before": {
          content: "' '",
          whiteSpace: "pre",
        },
      },
    },
  }),
  { name: "AnimatedLetters" }
);
