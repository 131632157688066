// Libraries
import React from "react";
import { NavLink } from "react-router-dom";
import { useOvermind } from "@overmind/index";

// Global
import { LINKEDIN } from "@global/index";

// Icons
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

// UI Components
import Button from "@components/basics/button";
import AnimatedLetters from "@components/basics/animated-letters";
import MenuIcon from "@components/basics/menu-icon";
import Layout from "@components/layout";
import { useStyles } from "./Home.styles";

const backgroundImage = require("@images/background.jpg");

const HomePage: React.FC = () => {
  const classes = useStyles();
  const {
    state: {
      project: { list },
    },
  } = useOvermind();
  const latestWork = list[0];

  return (
    <Layout contact={false} hideHeader title="Welcome">
      <div className={classes.container}>
        <div className={classes.left} style={{ backgroundImage: `url(${backgroundImage})` }} />
        <div className={classes.right}>
          <MenuIcon className={classes.menu} />
          <div className={classes.rightContainer}>
            <div className={classes.text}>
              <AnimatedLetters text="Hello!" />
              <AnimatedLetters text="I'm Atilla" />
              <AnimatedLetters text="Software Engineer" />
            </div>
            <div className={classes.subText}>
              <AnimatedLetters text="UI, UX, ReactJS, Vanilla JS, CSS, Storybook" />
            </div>
            {latestWork && (
              <div className={classes.latest}>
                <span className={classes.latestText}>
                  <LabelImportantIcon />
                  See latest work:
                </span>
                <NavLink to={`/project/${latestWork.slug}`}>{latestWork.title}</NavLink>
              </div>
            )}
            <Button size="medium" color="secondary" href={LINKEDIN} external>
              Say Hello
            </Button>
          </div>
          <div className={classes.footer}>
            <a href={LINKEDIN} target="_blank" rel="noreferrer">
              Linkedin
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
