// Libraries
import React, { useEffect } from "react";
import clsx from "clsx";

// UI Components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { useStyles } from "./PageTitle.style";

type Props = {
  img?: string;
  onToggle: (value: boolean) => void;
  size?: "large" | "medium";
};

const PageTitle: React.FC<Props> = ({ children, img, onToggle, size = "large" }) => {
  const classes = useStyles();

  useEffect(() => {
    const frame = document.getElementById("frame");
    const callback = (event: MouseEvent) => {
      if ((event.target as HTMLDivElement).scrollTop >= 175) {
        onToggle(true);
      } else {
        onToggle(false);
      }
    };

    frame.addEventListener("scroll", callback);

    return () => {
      frame.removeEventListener("scroll", callback);
    };
  }, []);

  return (
    <Box className={clsx(classes.container, classes[size])}>
      <Container disableGutters className={classes.wrapper}>
        <h1 className={classes.title}>{children}</h1>
        {img && <div className={classes.img} style={{ backgroundImage: `url(${img})` }} />}
      </Container>
    </Box>
  );
};

export default PageTitle;
