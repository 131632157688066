import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      background: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 4, 0, 4),
      color: theme.palette.common.white,
      fontSize: 16,
      fontWeight: 500,
      width: "100%",
      height: 90,
      lineHeight: 1.2,
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(3, 4, 3, 4),
        fontSize: 12,
      },
    },
    right: {
      marginLeft: "auto",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
      "& > a": {
        color: theme.palette.common.white,
        textDecoration: "none",
        marginLeft: theme.spacing(3),
        "&:first-child": {
          marginLeft: 0,
        },
        "&:hover": {
          color: theme.palette.secondary.main,
        },
      },
    },
  }),
  { name: "Footer" }
);
