import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      paddingTop: theme.spacing(4),
    },
    preview: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      wordBreak: "break-word",
      overflow: "hidden",
    },
    previewContainer: {
      overflow: "auto",
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  }),
  { name: "PlaceholderAppPreview" }
);
