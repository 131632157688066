import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: "fixed",
      left: "3%",
      bottom: "1.5%",
      background: theme.palette.secondary.main,
      padding: 25,
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      width: 700,
      border: `2px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 50px)",
      },
    },
    revoke: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    text: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      paddingRight: 20,
      flex: 1,
    },
  }),
  { name: "Consent" }
);
