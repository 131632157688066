// Libraries
import React from "react";
import { Link } from "react-router-dom";
import { ProjectItem } from "@overmind/namespaces/project/types";

// UI Components
import Chip from "@material-ui/core/Chip";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Box from "@material-ui/core/Box";
import { useStyles } from "./ProjectCard.style";

type Props = {
  item?: ProjectItem;
};

const ProjectCard: React.FC<Props> = ({ item: { slug, title, wip, hidden } }) => {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  const rootComponent = (
    <Box className={classes.root}>
      <Box className={classes.title}>{title}</Box>
      {wip && <Chip label="Details Soon" />}
      <ArrowRightAlt className={classes.icon} />
    </Box>
  );

  if (wip) {
    return <Box className={classes.wip}>{rootComponent}</Box>;
  }

  return (
    <Link className={classes.link} to={`/project/${slug}`}>
      {rootComponent}
    </Link>
  );
};

export default ProjectCard;
