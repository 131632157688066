export const FIREBASE = {
  apiKey: "AIzaSyDWmlXupNjqddZS6aQMWxr1Jn52jDY50No",
  authDomain: "atillasertkaya-38093.firebaseapp.com",
  databaseURL: "https://atillasertkaya-38093.firebaseio.com",
  projectId: "atillasertkaya-38093",
  storageBucket: "atillasertkaya-38093.appspot.com",
  messagingSenderId: "318812303041",
};

export const LINKEDIN = "https://www.linkedin.com/in/atilla-sertkaya/";

export const GOOGLE_TRACKING_ID = "UA-97842232-1";
