import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    content: {
      color: theme.palette.common.white,
      fontSize: 24,
      lineHeight: 1.5,
      paddingBottom: 30,
      overflowWrap: "break-word",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      "& strong": {
        color: theme.palette.secondary.main,
        fontWeight: 600,
      },
      "& li": {
        marginBottom: 10,
      },
      "& a": {
        color: theme.palette.secondary.main,
      },
      "& img": {
        width: "100%",
        height: "auto",
        display: "block",
        marginBottom: 30,
      },
    },
  }),
  { name: "Content" }
);
