/* eslint-disable import/no-duplicates */
import firebase from "firebase/app";
import { FIREBASE } from "@global/index";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";

firebase.initializeApp(FIREBASE);

export default (() => {
  let auth: firebase.auth.Auth;
  let database: firebase.database.Database;
  let storage: firebase.storage.Storage;

  return {
    initialize() {
      database = firebase.database();
      storage = firebase.storage();
      auth = firebase.auth();
    },
    auth() {
      return auth;
    },
    database() {
      return database;
    },
    storage() {
      return storage;
    },
    timestamp() {
      return firebase.database.ServerValue.TIMESTAMP;
    },
  };
})();
