import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    info: {
      paddingBottom: 30,
    },
    root: {
      paddingBottom: "24%",
    },
  }),
  { name: "ContactPage" }
);
