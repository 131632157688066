import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    "@keyframes indicatorAnim": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.5,
      },
      "100%": {
        opacity: 1,
      },
    },
    newsIndicator: {
      position: "fixed",
      right: -2,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 9999,
      background: theme.palette.common.white,
      padding: theme.spacing(3, 1),
      display: "flex",
      flexDirection: "column",
      color: theme.palette.primary.main,
      textDecoration: "none",
      border: `2px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    newsIndicatorIcon: {
      transform: "rotate(-90deg)",
      position: "relative",
      marginBottom: theme.spacing(1),
      animation: "$indicatorAnim 1s linear infinite",
      color: theme.palette.secondary.main,
    },
    newsIndicatorText: {
      writingMode: "vertical-lr",
      transform: "rotate(180deg)",
      fontSize: 14,
      fontWeight: 500,
    },
  }),
  { name: "Layout" }
);
