export const adminLinks = {
  login: "/kijim/login",
  dashboard: "/kijim/dashboard",
  pages: "/kijim/pages",
  page: {
    path: "/kijim/page/:id?",
    getPath: (id: string): string => `/kijim/page/${id}`,
  },
  projects: "/kijim/projects",
  project: {
    path: "/kijim/project/:id?",
    getPath: (id: string): string => `/kijim/project/${id}`,
  },
};

export const appLinks = {
  placeholder: "/apps/placeholder",
};
