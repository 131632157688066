import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      cursor: "pointer",
      width: 50,
      flex: "0 0 50px",
      display: "block",
      [theme.breakpoints.down("sm")]: {
        width: 40,
        flex: "0 0 40px",
      },
      "&:hover": {
        "& > span:nth-child(1)": {
          width: "80%",
        },
        "& > span:nth-child(2)": {
          width: "100%",
        },
        "& > span:nth-child(3)": {
          width: "80%",
        },
      },
      "& > span": {
        height: 5,
        marginBottom: 6,
        background: theme.palette.primary.main,
        display: "block",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          height: 3,
        },
        transition: "all 300ms",
        "&:nth-child(2)": {
          width: "80%",
        },
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    active: {
      "& > span": {
        background: theme.palette.primary.main,
      },
    },
  }),
  { name: "MenuIcon" }
);
