import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    "@keyframes skilAnim": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.5,
      },
      "100%": {
        opacity: 1,
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    item: {
      marginBottom: 25,
      width: "calc(33.333% - 20px)",
      flex: "0 0 calc(33.333% - 20px)",
      marginRight: 30,
      "&:nth-child(3n)": {
        marginRight: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        flex: "0 0 100%",
      },
    },
    itemBar: {
      width: "100%",
      height: 12,
      position: "relative",
      overflow: "hidden",
      background: theme.palette.secondary.dark,
      "& > div": {
        animation: "$skilAnim 1s linear infinite",
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        opacity: 1,
        background: theme.palette.secondary.main,
      },
    },
    itemText: {
      color: theme.palette.common.white,
      fontSize: 20,
      marginBottom: 15,
      display: "block",
    },
  }),
  { name: "SkillsPage" }
);
