import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    icon: {
      marginLeft: "auto",
      fontSize: 50,
      color: theme.palette.secondary.main,
      opacity: 0,
      transition: "all 400ms",
    },
    link: {
      textDecoration: "none",
      borderBottom: `2px solid ${theme.palette.common.white}`,
      display: "block",
      "&:last-child": {
        borderBottom: 0,
      },
      "&:hover": {
        "& $root": {
          transform: "translate(20px, 0)",
        },
        "& $title": {
          color: theme.palette.secondary.main,
        },
        "& $icon": {
          opacity: 1,
        },
      },
    },
    root: {
      position: "relative",
      padding: theme.spacing(5, 2),
      display: "flex",
      alignItems: "center",
      transition: "all 400ms",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 2),
      },
    },
    title: {
      fontSize: 35,
      fontWeight: 500,
      color: theme.palette.common.white,
      transition: "all 300ms",
      zIndex: 2,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    wip: {
      borderBottom: `2px solid ${theme.palette.common.white}`,
      "& $root": {
        opacity: 0.5,
      },
    },
  }),
  { name: "ProjectCard" }
);
