import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      fontFamily: theme.typography.fontFamily,
      cursor: "pointer",
      fontWeight: 600,
      display: "inline-block",
      position: "relative",
      overflow: "hidden",
      border: 0,
      outline: 0,
      boxShadow: "none",
      "&:before": {
        content: "attr(data-text)",
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 300ms",
        transform: "translateY(100%)",
        opacity: 0,
      },
      "& > span": {
        display: "inline-block",
        transform: "translateY(0)",
        transition: "all 300ms",
      },
      "&:hover": {
        "&:before": {
          transform: "translateY(0)",
          opacity: 0.88,
        },
        "& > span": {
          transform: "translateY(-100%)",
          opacity: 0,
        },
      },
    },
    primary: {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    secondary: {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    small: {
      padding: "8px 20px",
      fontSize: 14,
    },
    medium: {
      padding: "13px 30px",
      fontSize: 16,
    },
    large: {
      padding: "18px 40px",
      fontSize: 20,
    },
    fullWidth: {
      width: "100%",
    },
    loading: {
      pointerEvents: "none",
      "& > span, &:before": {
        opacity: 0,
        visibility: "hidden",
      },
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }),
  { name: "Button" }
);
