// Libraries
import React, { useState } from "react";
import clsx from "clsx";

// Hooks
import { useHistory } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

// UI Components
import AnimatedLetters from "@components/basics/animated-letters";
import BackButton from "@material-ui/icons/KeyboardBackspace";
import MenuIcon from "@components/basics/menu-icon";
import PageTitle from "../page-title";
import { useStyles } from "./Header.style";

type Props = {
  img?: string;
  size?: "medium" | "large";
  title?: string;
};

const Header: React.FC<Props> = ({ img, size, title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [titleShow, setTitleShow] = useState(false);
  const isTitleShown = titleShow || isMobile;
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <header className={classes.container}>
        <BackButton color="primary" fontSize="large" className={classes.back} onClick={history.goBack} />
        <span className={clsx(classes.title, isTitleShown && classes.titleShow)}>{title}</span>
        <div className={classes.right}>
          {img && (
            <div
              className={clsx(classes.image, isTitleShown && classes.titleShow)}
              style={{ backgroundImage: `url(${img})` }}
            />
          )}
          <MenuIcon />
        </div>
      </header>
      {!isMobile && (
        <PageTitle img={img} onToggle={setTitleShow} size={size}>
          <AnimatedLetters text={title} />
        </PageTitle>
      )}
    </>
  );
};

export default Header;
