import { AsyncAction } from "overmind";
import { ProjectItem, ProjectImage } from "./types";

export const getAll: AsyncAction = async ({
  state,
  effects: {
    firebase: { database },
  },
}) => {
  state.project.status.list = "loading";

  const snapshot = await database().ref("projects").orderByChild("date").once("value");

  const projects: ProjectItem[] = [];

  if (snapshot.exists()) {
    snapshot.forEach((item) => {
      projects.push({
        ...item.val(),
        id: item.key,
      });
    });
  }

  state.project.list = projects.reverse();
  state.project.status.list = "done";
};

export const get: AsyncAction<string> = async (
  {
    state,
    effects: {
      firebase: { database },
    },
  },
  id
) => {
  const snapshot = await database().ref("projects").child(id).once("value");

  if (snapshot.exists()) {
    state.project.detail = {
      id,
      ...snapshot.val(),
    };
  }
};

export const getOne: AsyncAction<string> = async ({ state }, slug) => {
  state.project.status.detail = "loading";

  state.project.list.forEach((project) => {
    if (project.slug === slug) {
      state.project.detail = { ...project };
    }
  });

  state.project.status.detail = "done";
};

export const save: AsyncAction<ProjectItem> = async (
  {
    actions,
    state,
    effects: {
      firebase: { database, timestamp },
    },
  },
  { content, fields, id, slug, title, images, hidden, tags, wip = false }
) => {
  state.project.status.insert = "loading";
  await actions.project.uploadImages({ id, images });

  await database()
    .ref("projects")
    .child(id)
    .update({
      content,
      date: timestamp(),
      fields,
      hidden,
      images: state.project.uploadImages.map(({ key, url }) => ({ key, url })),
      slug,
      tags,
      title,
      wip,
    });
  state.project.saved = true;
  state.project.status.insert = "done";
};

export const remove: AsyncAction<string> = async (
  {
    state,
    effects: {
      firebase: { database },
    },
  },
  id
) => {
  state.project.list = state.project.list.filter((project) => project.id !== id);
  await database().ref("projects").child(id).remove();
};

export const uploadImages: AsyncAction<{ id: string; images: ProjectImage[] }> = async (
  { actions, state },
  { id, images: rawImages }
) => {
  const images = [...rawImages];
  const promises: unknown[] = [];

  images.forEach((image) => {
    if (image.file) {
      promises.push(actions.project.uploadImage({ projectId: id, ...image }));
    }
  });

  const urls = await Promise.all(promises);

  urls.forEach((newImage: { key: string; url: string }) => {
    images.forEach((image, index) => {
      if (image.key === newImage.key) {
        images[index] = {
          ...image,
          url: newImage.url,
        };
      }
    });
  });

  state.project.uploadImages = images;
};

export const uploadImage: AsyncAction<ProjectImage & { projectId: string }, { key: string; url: string }> = async (
  {
    effects: {
      firebase: { storage },
    },
  },
  { projectId, id, file }
) => {
  const snapshot = await storage().ref().child(`/projects/${projectId}/images/${id}`).put(file);

  const newImageUrl = await snapshot.ref.getDownloadURL();

  return { key: id, url: newImageUrl };
};

export const removeImage: AsyncAction<{ projectId: string; id: string }> = async (
  {
    effects: {
      firebase: { storage },
    },
  },
  { id, projectId }
) => {
  await storage().ref().child(`/projects/${projectId}/images/${id}`).delete();
};
