import React, { useRef, useCallback } from "react";
import { toPng } from "html-to-image";
import { useForm } from "react-final-form";
import { useOvermind } from "@overmind/index";
import clsx from "clsx";

// UI Components
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import GetAppRounded from "@material-ui/icons/GetAppRounded";
import { useStyles } from "./Preview.style";

const Preview: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { getState } = useForm();
  const { values } = getState();
  const { actions } = useOvermind();

  const generateImage = useCallback(() => {
    if (ref.current) {
      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const fileName = `placeholder-${values.width}x${values.height}-${values.template}.png`;
          const link = document.createElement("a");
          link.download = fileName;
          link.href = dataUrl;
          link.click();
          actions.global.showSnackbar({
            message: "Congrats, the placeholder is successfully generated 💪",
            autoHide: true,
          });
        })
        .catch(() => {
          actions.global.showSnackbar({
            autoHide: true,
            message: "We are very sorry this issue happened, we're taking a look.",
            type: "error",
          });
        });
    }
  }, [ref]);

  return (
    <Box className={classes.container}>
      <Box mt={3} mb={3} display="flex" alignItems="center">
        <Typography variant="h4">Preview</Typography>
        <Box mr={1} />
        <IconButton onClick={generateImage}>
          <GetAppRounded fontSize="small" />
        </IconButton>
      </Box>
      <Box className={classes.previewContainer}>
        <div ref={ref}>
          <Box
            className={clsx(classes.preview, classes[values.template as "primary" | "secondary"])}
            style={{ height: Number(values.height || 300), width: Number(values.width || 300) }}
          >
            <Typography variant="h6">{values.text}</Typography>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Preview;
