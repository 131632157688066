// Libraries
import React, { useEffect, useRef } from "react";

import { useStyles } from "./MousePointer.style";

const MousePointer: React.FC = () => {
  const classes = useStyles();
  const ref = useRef<HTMLSpanElement>();

  useEffect(() => {
    const callbackMouseMove = ({ pageX, pageY }: MouseEvent) => {
      if (ref.current) {
        const x = pageX - 20;
        const y = pageY - 20;
        ref.current.style.opacity = "1";
        ref.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
      }
    };
    const callbackMouseLeave = () => {
      if (ref.current) {
        ref.current.style.opacity = "0";
      }
    };
    const callbackMouseEnter = () => {
      if (ref.current) {
        ref.current.style.opacity = "1";
      }
    };
    document.addEventListener("mousemove", callbackMouseMove);
    document.addEventListener("mouseleave", callbackMouseLeave);
    document.addEventListener("mouseenter", callbackMouseEnter);

    return () => {
      document.removeEventListener("mousemove", callbackMouseMove);
    };
  }, []);

  return <span className={classes.root} ref={ref} />;
};

export default MousePointer;
