import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      background: theme.palette.common.white,
      padding: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    option: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "&:after": {
        content: "''",
        width: 24,
        flex: "0 0 24px",
        height: 24,
        borderRadius: 4,
        marginLeft: "auto",
      },
    },
    primaryOption: {
      "&:after": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    secondaryOption: {
      "&:after": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
  { name: "PlaceholderApp" }
);
