// Libraries
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Helpers
import { useOvermind } from "@overmind/index";

const Loader: React.FC = ({ children }) => {
  const location = useLocation();
  const { actions } = useOvermind();

  useEffect(() => {
    actions.global.setMenu(false);
    actions.global.trackPage();
    document.getElementById("frame").scrollTop = 0;
  }, [location]);

  return <>{children}</>;
};

export default Loader;
