// Libraries
import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { adminLinks, appLinks } from "@global/routes";

// UI Components
import { makeStyles } from "@material-ui/core";
import PrivateRoute from "@components/basics/private-route";

// Apps
import PlaceholderApp from "@pages/apps/placeholder";

// Public Pages
import HomePage from "@pages/home";
import Page from "@pages/page";
import ProjectsPage from "@pages/projects";
import SkillsPage from "@pages/skills";
import ContactPage from "@pages/contact";
import ProjectDetailPage from "@pages/project-detail";
import AboutPage from "@pages/about";
import NotFoundPage from "@pages/not-found";

// Admin Pages
const AdminLoginPage = React.lazy(() => import("@pages/admin/login"));
const AdminDashboardPage = React.lazy(() => import("@pages/admin/dashboard"));
const AdminProjectsPage = React.lazy(() => import("@pages/admin/projects"));
const AdminProjectDetailPage = React.lazy(() => import("@pages/admin/project-detail"));
const AdminPagesPage = React.lazy(() => import("@pages/admin/pages"));
const AdminPageDetailPage = React.lazy(() => import("@pages/admin/page-detail"));

const routes = [
  { path: "/", name: "Home", Component: HomePage },
  { path: "/page/:slug", name: "Page", Component: Page },
  { path: "/projects", name: "Projects", Component: ProjectsPage },
  { path: "/project/:slug", name: "Project", Component: ProjectDetailPage },
  { path: "/skills", name: "Skills", Component: SkillsPage },
  { path: "/contact", name: "Contact", Component: ContactPage },
  { path: "/about", name: "About", Component: AboutPage },
  { path: adminLinks.login, name: "Login", Component: AdminLoginPage },
  { path: adminLinks.dashboard, name: "Dashboard", Component: AdminDashboardPage, isPrivate: true },
  { path: adminLinks.projects, name: "Projects", Component: AdminProjectsPage, isPrivate: true },
  { path: adminLinks.project.path, name: "Project Detail", Component: AdminProjectDetailPage, isPrivate: true },
  { path: adminLinks.pages, name: "Pages", Component: AdminPagesPage, isPrivate: true },
  { path: adminLinks.page.path, name: "Page Detail", Component: AdminPageDetailPage, isPrivate: true },
  { path: appLinks.placeholder, name: "Placeholder", Component: PlaceholderApp },
  { path: "/404", name: "NotFound", Component: NotFoundPage },
  { path: "*", name: "NotFound", Component: NotFoundPage },
];

const useStyles = makeStyles(
  () => ({
    pageEnter: {
      height: "100%",
      opacity: 0,
    },
    pageEnterActive: {
      opacity: 1,
      transition: "all 500ms",
    },
    pageExit: {
      height: "100%",
      opacity: 1,
    },
    pageExitActive: {
      opacity: 0,
      transition: "all 500ms",
    },
  }),
  { name: "Route" }
);

const RouteProvider: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={500}
        classNames={{
          enter: classes.pageEnter,
          enterActive: classes.pageEnterActive,
          exitActive: classes.pageExitActive,
          exit: classes.pageExit,
        }}
      >
        <Switch location={location}>
          {routes.map(({ name, path, isPrivate, Component }) =>
            isPrivate ? (
              <PrivateRoute key={name} exact path={path} component={Component} />
            ) : (
              <Route key={name} exact path={path} component={Component} />
            )
          )}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RouteProvider;
