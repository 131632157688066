// Libraries
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useOvermind } from "@overmind/index";

// UI Components
import Box from "@material-ui/core/Box";
import { useStyles } from "./PageLoading.style";

// Icons
const catIcon = require("./icons/cat.png");
const tailIcon = require("./icons/tail.png");

const PageLoading: React.FC = () => {
  const classes = useStyles();
  const {
    state: {
      global: { appLoading },
    },
  } = useOvermind();
  const [completed, setCompleted] = useState(!appLoading);
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    if (!appLoading) {
      setTimeout(() => {
        setAnimated(true);
        setTimeout(() => {
          setCompleted(true);
        }, 500);
      }, 500);
    }
  }, [appLoading]);

  if (completed) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, animated && classes.rootAnimated)}>
      <Box className={classes.bar} />
      <Box className={classes.container}>
        <img className={classes.animationCat} src={catIcon} alt="Atilla" />
        <img className={classes.animationTail} src={tailIcon} alt="Sertkaya" />
      </Box>
    </Box>
  );
};

export default PageLoading;
