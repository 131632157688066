// Libraries
import React from "react";

// Components
import Box from "@material-ui/core/Box";
import { useStyles } from "./Frame.style";

const Frame: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} id="frame">
      {children}
    </Box>
  );
};

export default Frame;
