import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      paddingBottom: 40,
    },
  }),
  { name: "Skeleton" }
);
