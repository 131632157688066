import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: "100%",
      height: "100%",
      background: theme.palette.primary.main,
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    footer: {
      position: "absolute",
      bottom: "5%",
      right: "5%",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
      },
      "& > a": {
        textDecoration: "none",
        color: theme.palette.common.white,
        fontSize: 15,
        marginLeft: 25,
        "&:hover": {
          color: theme.palette.secondary.main,
        },
      },
    },
    latest: {
      fontSize: 20,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > a": {
        color: theme.palette.secondary.main,
      },
    },
    latestText: {
      color: theme.palette.common.white,
      paddingRight: 15,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    left: {
      width: "40%",
      flex: "0 0 40%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 400,
      },
      "&:before": {
        content: "''",
        left: 0,
        top: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        opacity: 0.6,
      },
    },
    menu: {
      position: "absolute",
      right: 40,
      top: 27,
      "& > span": {
        background: theme.palette.secondary.main,
      },
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
      },
    },
    right: {
      flex: 1,
      height: "100%",
      padding: "5%",
      overflow: "auto",
      display: "flex",
      alignItems: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
        display: "block",
      },
    },
    rightContainer: {
      marginTop: "auto",
      marginBottom: "auto",
    },
    subText: {
      color: theme.palette.common.white,
      fontSize: 25,
      lineHeight: 1.2,
      paddingBottom: 20,
      opacity: 0.67,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    text: {
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 60,
      lineHeight: 1.2,
      paddingBottom: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 40,
      },
    },
  }),
  { name: "HomePage" }
);
