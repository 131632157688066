import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      background: theme.palette.secondary.main,
    },
    wrapper: {
      height: 400,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    text: {
      paddingBottom: 40,
    },
  }),
  { name: "Contact" }
);
