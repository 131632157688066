// Libraries
import React from "react";
import { LINKEDIN } from "@global/index";

// UI Components
import Box from "@material-ui/core/Box";
import { useStyles } from "./Footer.style";

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.container}>
      <Box>© {new Date().getFullYear()} atillasertkaya.com All Rights Reserved.</Box>
      <Box className={classes.right}>
        <a href={LINKEDIN} target="_blank" rel="noreferrer">
          Linkedin
        </a>
      </Box>
    </footer>
  );
};

export default Footer;
