/* eslint-disable react/no-array-index-key */
// Libraries
import React from "react";

import { useStyles } from "./AnimatedLetters.style";

type Props = {
  text: string;
};

const AnimatedLetters: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }

  return (
    <div className={classes.container}>
      {Array.from(text).map((letter, letterIndex) => (
        <span key={`letter-${letter}-${letterIndex}`}>{letter.trim()}</span>
      ))}
    </div>
  );
};

export default AnimatedLetters;
