import React, { useState, useEffect } from "react";
import { useOvermind } from "@overmind/index";

// UI Components
import Button from "@components/basics/button";
import { Slide } from "@material-ui/core";
import { useStyles } from "./Consent.style";

const Consent: React.FC = () => {
  const [enable, setEnable] = useState(false);
  const classes = useStyles();
  const { state, actions } = useOvermind();

  useEffect(() => {
    setTimeout(() => {
      setEnable(true);
    }, 300);
  }, []);

  if (!enable) {
    return null;
  }

  return (
    <Slide direction="up" in={state.global.consent === null} unmountOnExit timeout={1000}>
      <div className={classes.container}>
        <div className={classes.text}>
          This website uses cookies to enhance the user experience.
          <br />
          However, you can always{" "}
          <span role="button" className={classes.revoke} onClick={() => actions.global.setConsent(false)}>
            disable
          </span>{" "}
          it.
        </div>
        <Button
          size="small"
          onClick={() => {
            actions.global.setConsent(true);
          }}
        >
          I Understant that
        </Button>
      </div>
    </Slide>
  );
};

export default Consent;
