import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Providers
import { Provider } from "overmind-react";
import { createOvermind } from "overmind";
import { config } from "@overmind/index";

import Loader from "@providers/loader";
import RouteProvider from "@providers/route";
import ThemeProvider from "@providers/theme";
import SnackbarProvider from "@providers/snackbar";

// UI Components
import Menu from "@components/layout/components/menu";
import Frame from "@components/basics/frame";
import Maintenance from "@components/basics/maintenance";
import MousePointer from "@components/basics/mouse-pointer";

const overmind = createOvermind(config);

const App: React.FC = () => {
  const isMaintenance = false;

  if (isMaintenance) {
    return <Maintenance />;
  }

  return (
    <ThemeProvider>
      <Provider value={overmind}>
        <Suspense fallback={<span />}>
          <Frame>
            <Router>
              <Loader />
              <RouteProvider />
              <SnackbarProvider />
              <Menu />
              <MousePointer />
            </Router>
          </Frame>
        </Suspense>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
