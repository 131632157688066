import firebase from "firebase/app";

export default (() => {
  let database: firebase.database.Database;

  return {
    initialize() {
      database = firebase.database();
    },
    getKey() {
      return database.ref("projects").push().key;
    },
    getImageKey(id: string) {
      return database.ref("projects").child(id).push().key;
    },
  };
})();
