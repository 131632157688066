// Libraries
import React from "react";
import { useOvermind } from "@overmind/index";
import clsx from "clsx";

import { useStyles } from "./MenuIcon.style";

type Props = {
  className?: string;
};

const MenuIcon: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const {
    actions: {
      global: { setMenu },
    },
    state: {
      global: { menu },
    },
  } = useOvermind();

  return (
    <span
      role="menubar"
      aria-label="menu"
      className={clsx(classes.container, className, menu && classes.active)}
      onClick={() => setMenu(!menu)}
    >
      <span />
      <span />
      <span />
    </span>
  );
};

export default MenuIcon;
