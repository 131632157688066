import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    buttons: {
      display: "flex",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
  }),
  { name: "AboutPage" }
);
