import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  {
    root: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    text: { color: "white" },
  },
  { name: "Maintenance" }
);
