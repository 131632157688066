import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      height: 200,
      background: theme.palette.common.white,
      position: "relative",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    img: {
      width: 200,
      height: 200,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      bottom: 0,
      right: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    medium: {
      "& h1": {
        fontSize: 85,
        [theme.breakpoints.down("sm")]: {
          fontSize: 50,
        },
      },
      "& $img": {
        width: 100,
        height: 100,
        bottom: -50,
        border: `7.5px solid ${theme.palette.common.white}`,
      },
    },
    large: {
      "& h1": {
        fontSize: 105,
        [theme.breakpoints.down("sm")]: {
          fontSize: 60,
        },
      },
      "& $img": {
        width: 200,
        height: 200,
        bottom: -100,
        border: `15px solid ${theme.palette.common.white}`,
      },
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      margin: 0,
      position: "relative",
      lineHeight: 1,
      "&:before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: "100%",
        width: "15%",
        height: 10,
        background: theme.palette.secondary.main,
      },
    },
    wrapper: {
      position: "relative",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  }),
  { name: "PageTitle" }
);
