import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: "'PT Sans', Arial, Helvetica",
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiContainer: {
      disableGutters: {
        padding: "0!important",
        [defaultTheme.breakpoints.down("sm")]: {
          padding: "40px!important",
        },
      },
      root: {
        width: "70%",
        padding: "120px 0!important",
        [defaultTheme.breakpoints.down("sm")]: {
          width: "100%",
          padding: "40px!important",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#9e9e9e",
      main: "#333",
    },
    secondary: {
      dark: "#b5838d",
      main: "#fba92c",
    },
    text: {
      secondary: "#fff",
    },
  },
});

const Theme: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline>{children}</CssBaseline>
  </ThemeProvider>
);

export default Theme;
