import { OnInitialize } from "overmind";

/**
 * Initialize the app.
 * Here is a place to initialize state and effects
 */
export const onInitialize: OnInitialize = ({ actions, effects }) => {
  effects.firebase.initialize();
  effects.project.initialize();
  effects.page.initialize();

  actions.project.getAll();

  actions.auth.getAuth();

  actions.global.getConsent();

  setTimeout(() => {
    actions.global.hideAppLoading();
  }, 2500);
};
