import React from "react";
import { Form } from "react-final-form";
import clsx from "clsx";

// UI Components
import Select from "@components/basics/form-fields/select";
import Input from "@components/basics/form-fields/input";
import Layout from "@components/layout";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useStyles } from "./Placeholder.style";
import Preview from "./components/preview/Preview";

const Placeholder: React.FC = () => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  return (
    <Layout title="Placeholder">
      <Container>
        <Box className={classes.container}>
          <Form
            initialValues={{ height: 300, text: "Placeholder", width: 300, template: "primary" }}
            onSubmit={onSubmit}
            render={() => (
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={3}>
                      <Typography variant="h4">Generate Placeholder</Typography>
                    </Box>
                    <Typography variant="h6">
                      You can simply generate dummy images for your applications by using this tool.
                    </Typography>
                    <Box mb={3} />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Input name="text" label="Text" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="width" label="Width" type="number" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="height" label="Height" type="number" />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          data={[
                            {
                              label: <Box className={clsx(classes.option, classes.primaryOption)}>Primary</Box>,
                              value: "primary",
                            },
                            {
                              label: <Box className={clsx(classes.option, classes.secondaryOption)}>Secondary</Box>,
                              value: "secondary",
                            },
                            {
                              disabled: true,
                              label: <Box className={classes.option}>Custom ( coming soon )</Box>,
                              value: "custom",
                            },
                          ]}
                          name="template"
                          label="Template"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Preview />
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Box>
      </Container>
    </Layout>
  );
};

export default Placeholder;
