import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    img: {
      marginBottom: 50,
      width: "100%",
    },
    subtitle: {
      color: theme.palette.common.white,
      marginBottom: 30,
      position: "relative",
      display: "inline-flex",
      "&:before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: "calc(100% + 6px)",
        width: 40,
        height: 4,
        background: theme.palette.secondary.main,
      },
    },
    tags: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: 30,
      flexWrap: "wrap",
      "& > *": {
        marginRight: 20,
        marginBottom: 20,
      },
    },
  }),
  { name: "ProjectDetailPage" }
);
