import { AsyncAction } from "overmind";
import { PageItem } from "./types";

export const getAll: AsyncAction = async ({
  state,
  effects: {
    firebase: { database },
  },
}) => {
  state.page.loading = true;

  const snapshot = await database().ref("pages").orderByChild("date").once("value");

  const pages: PageItem[] = [];

  if (snapshot.exists()) {
    snapshot.forEach((item) => {
      pages.push({
        ...item.val(),
        id: item.key,
      });
    });
  }

  state.page.list = pages.reverse();
  state.page.detail = null;
  state.page.loading = false;
};

export const get: AsyncAction<string> = async (
  {
    state,
    effects: {
      firebase: { database },
    },
  },
  id
) => {
  state.page.loading = true;

  const snapshot = await database().ref("pages").child(id).once("value");

  if (snapshot.exists()) {
    state.page.detail = {
      id: snapshot.key,
      ...snapshot.val(),
    };
  }

  state.page.loading = false;
};

export const save: AsyncAction<PageItem> = async (
  {
    state,
    effects: {
      firebase: { database, timestamp },
    },
  },
  { id, slug, title, hidden, content }
) => {
  state.page.loading = true;

  await database()
    .ref("pages")
    .child(id || slug)
    .update({
      content,
      date: timestamp(),
      slug,
      title,
      hidden,
    });

  state.page.loading = false;
};

export const remove: AsyncAction<string> = async (
  {
    state,
    effects: {
      firebase: { database },
    },
  },
  id
) => {
  state.page.list = state.page.list.filter((page) => page.id !== id);
  await database().ref("pages").child(id).remove();
};
