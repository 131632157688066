import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    "@keyframes chipAnim": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.6,
      },
      "100%": {
        opacity: 1,
      },
    },
    chip: {
      position: "absolute",
      left: "100%",
      marginLeft: theme.spacing(2),
      top: "50%",
      transform: "translateY(-50%)",
      animation: "$chipAnim 1s linear infinite",
    },
    container: {
      width: 600,
      height: "100%",
      background: theme.palette.secondary.main,
      position: "fixed",
      right: 0,
      top: 0,
      zIndex: 9999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    disableLink: {
      opacity: 0.6,
      cursor: "auto",
      pointerEvents: "none",
    },
    menuIcon: {
      position: "absolute",
      right: 30,
      top: 30,
      transition: "all 300ms",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
      },
      "& svg": {
        fontSize: 45,
      },
    },
    menuList: {
      listStyle: "none",
      "& > li": {
        marginBottom: theme.spacing(3),
        textAlign: "center",
        position: "relative",
      },
      "& a": {
        color: theme.palette.primary.main,
        fontSize: 43,
        fontWeight: 500,
        position: "relative",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
          fontSize: 32,
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 10,
          bottom: 0,
          right: 0,
          width: "100%",
          margin: "auto",
          height: 4,
          background: theme.palette.common.white,
          opacity: 0,
          transition: "all 300ms",
        },
        "&.active:not($disableLink), &:hover:not($disableLink)": {
          "&:before": {
            opacity: 1,
          },
        },
        "&.active": {
          cursor: "auto",
          pointerEvents: "none",
        },
      },
    },
    root: {
      "&:before": {
        content: "''",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        position: "fixed",
        background: theme.palette.primary.main,
        zIndex: 999,
        opacity: 0,
        visibility: "hidden",
        transition: "all 500ms",
      },
    },
    rootOpen: {
      "&:before": {
        visibility: "visible",
        opacity: 0.7,
      },
    },
  }),
  { name: "Menu" }
);
