import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    back: {
      marginRight: "20px!important",
      transition: "all 300ms",
      transform: "translateX(0)",
      cursor: "pointer",
      "&:hover": {
        fill: `${theme.palette.secondary.main}!important`,
        transform: "translateX(-5px)",
      },
    },
    container: {
      width: "100%",
      height: 80,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 4, 0, 4),
      position: "sticky",
      top: 0,
      zIndex: 999,
      [theme.breakpoints.down("xs")]: {
        height: 70,
      },
    },
    image: {
      width: 40,
      flex: "0 0 40px",
      height: 40,
      borderRadius: "50%",
      marginLeft: "auto",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: theme.palette.secondary.main,
      position: "relative",
      marginRight: 30,
      opacity: 0,
      visibility: "hidden",
      transition: "all 300ms",
      transform: "translateY(100%)",
    },
    right: {
      marginLeft: "auto",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 23,
      fontWeight: "bold",
      opacity: 0,
      visibility: "hidden",
      transition: "all 300ms",
      transform: "translateY(100%)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      paddingRight: 10,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    titleShow: {
      transform: "translateY(0%)",
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "Header" }
);
