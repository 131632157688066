// Libraries
import React from "react";
import { Field } from "react-final-form";

// UI Components
import { TextField } from "@material-ui/core";
import { FieldValidator } from "final-form";

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  type?: string;
  validate?: FieldValidator<unknown>;
};

const Input: React.FC<Props> = ({ label, name, placeholder, type, validate }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => {
      const error = meta.touched && meta.error;

      return (
        <TextField
          {...input}
          error={Boolean(error)}
          helperText={error}
          label={label}
          placeholder={placeholder || label}
          type={type}
          variant="outlined"
          fullWidth
          InputLabelProps={{ style: { color: "#333" } }}
          size="small"
        />
      );
    }}
  </Field>
);

export default Input;
