// Libraries
import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useOvermind } from "@overmind/index";
import { appLinks } from "@global/routes";
import clsx from "clsx";

// Components
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Slide from "@material-ui/core/Slide";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { useStyles } from "./Menu.style";

const Menu: React.FC = () => {
  const ref = useRef<HTMLDivElement>();
  const classes = useStyles();
  const { actions, state } = useOvermind();

  useEffect(() => {
    const callback = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        actions.global.setMenu(false);
      }
    };

    document.addEventListener("click", callback, true);

    return () => {
      document.removeEventListener("click", callback);
    };
  }, []);

  return (
    <Box className={clsx(classes.root, state.global.menu && classes.rootOpen)}>
      <Slide direction="left" in={state.global.menu} timeout={400} unmountOnExit>
        <div className={classes.container} ref={ref}>
          <span className={classes.menuIcon} onClick={() => actions.global.setMenu(false)} role="button">
            <CloseOutlined color="primary" fontSize="large" />
          </span>
          <ul className={classes.menuList}>
            <li>
              <NavLink to="/" strict exact>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" strict exact>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/projects" strict exact>
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" strict exact className={classes.disableLink}>
                BLOG
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" strict exact>
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink to={appLinks.placeholder} strict exact>
                Placeholder
              </NavLink>
              <Chip className={classes.chip} label="New Tool" />
            </li>
          </ul>
        </div>
      </Slide>
    </Box>
  );
};

export default Menu;
