// Libraries
import React from "react";

// UI Components
import Box from "@material-ui/core/Box";
import MUISkeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "./Skeleton.style";

type Props = {
  active?: boolean;
  type?: "content" | "list";
};

const Skeleton: React.FC<Props> = ({ active, type }) => {
  const classes = useStyles();

  if (!active) {
    return null;
  }

  if (type === "list") {
    return (
      <Box className={classes.container}>
        {Array(10)
          .fill({})
          .map(() => (
            <>
              <MUISkeleton width="100%" height={100} />
              <br />
              <MUISkeleton width="100%" height={40} />
              <br />
            </>
          ))}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <MUISkeleton width="100%" height={30} />
      <br />
      <MUISkeleton width="100%" height={30} />
      <MUISkeleton width="100%" height={30} />
      <MUISkeleton width="100%" height={30} />
      <MUISkeleton width="100%" height={30} />
      <br />
      <MUISkeleton width="100%" height={30} />
      <MUISkeleton width="100%" height={30} />
      <br />
      <MUISkeleton width="100%" height={30} />
      <MUISkeleton width="100%" height={30} />
    </Box>
  );
};

export default Skeleton;
