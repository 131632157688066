import { State } from "./types";

export const state: State = {
  list: [],
  detail: null,
  saved: false,
  uploadImages: [],
  status: {
    detail: "loading",
    list: "loading",
    insert: "initial",
  },
};
