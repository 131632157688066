// Libraries
import React, { useEffect } from "react";
import { useOvermind } from "@overmind/index";

// UI Components
import Box from "@material-ui/core/Box";
import Button from "@components/basics/button";
import Content from "@components/basics/content";
import Container from "@material-ui/core/Container";
import Layout from "@components/layout";
import { useStyles } from "./About.style";

const avatar = require("@images/avatar.jpg");

const AboutPage: React.FC = () => {
  const classes = useStyles();
  const {
    actions,
    state: {
      page: { detail, loading },
    },
  } = useOvermind();

  useEffect(() => {
    actions.page.get("about");
  }, []);

  return (
    <Layout loading={loading} title={detail?.title ?? "Hello"} img={avatar}>
      <Container>
        <Content content={detail?.content} />
        <Box className={classes.buttons}>
          <Button href="/skills" color="secondary" size="small">
            Skills
          </Button>
          <Button href="/projects" color="secondary" size="small">
            Projects
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default AboutPage;
