import { Action } from "overmind";
import { GOOGLE_TRACKING_ID } from "@global/index";
import { SnackbarProps } from "./types";

export const setMenu: Action<boolean> = ({ state }, value) => {
  state.global.menu = value;
};

export const trackPage: Action = ({ state }) => {
  if (state.global.consent) {
    // @ts-ignore
    window?.gtag("config", GOOGLE_TRACKING_ID, { page_path: location.pathname });
  }
};

export const setConsent: Action<boolean> = ({ actions, state }, value) => {
  state.global.consent = value;
  localStorage.setItem("consent", `${value}`);
  if (!value) {
    actions.global.disableGA();
  }
};

export const getConsent: Action = ({ state, actions }) => {
  const consent = localStorage.getItem("consent");
  if (consent === "true") {
    state.global.consent = true;
  } else if (consent === "false") {
    actions.global.disableGA();
    state.global.consent = false;
  }
};

export const disableGA: Action = () => {
  // @ts-ignore
  window[`ga-disable-${GOOGLE_TRACKING_ID}`] = true;
};

export const showSnackbar: Action<SnackbarProps> = ({ state }, snackbarProps) => {
  state.global.snackbar = snackbarProps;
};

export const hideSnackbar: Action = ({ state }) => {
  state.global.snackbar = null;
};

export const hideAppLoading: Action = ({ state }) => {
  state.global.appLoading = false;
};
