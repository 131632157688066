// Libraries
import React from "react";

// Helpers
import { useOvermind } from "@overmind/index";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "@components/basics/button";

const SnackbarProvider: React.FC = () => {
  const {
    state: {
      global: { snackbar },
    },
    actions,
  } = useOvermind();

  if (snackbar) {
    const { action, autoHide, anchorOrigin, message, type = "success" } = snackbar;

    return (
      <Snackbar
        open
        autoHideDuration={autoHide ? 6000 : null}
        onClose={actions.global.hideSnackbar}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          severity={type}
          action={
            action && (
              <Button onClick={action.onClick} size="small">
                {action.label}
              </Button>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default SnackbarProvider;
