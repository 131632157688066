import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  () => ({
    image: {
      display: "block",
      width: "100%",
    },
    imageLoading: {
      display: "none",
    },
    root: {},
    skeleton: {
      paddingBottom: "56.25%",
    },
  }),
  { name: "LazyImage" }
);
