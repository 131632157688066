// Libraries
import React, { ReactNode } from "react";
import { Field } from "react-final-form";

// UI Components
import { MenuItem, TextField } from "@material-ui/core";
import { FieldValidator } from "final-form";

type Props = {
  label: string;
  name: string;
  validate?: FieldValidator<unknown>;
  data: { disabled?: boolean; label: ReactNode; value: string }[];
};

const Select: React.FC<Props> = ({ data = [], label, name, validate }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => {
      const error = meta.touched && meta.error;

      return (
        <>
          <TextField
            label={label}
            name={name}
            variant="outlined"
            fullWidth
            select
            size="small"
            error={Boolean(error)}
            helperText={error}
            SelectProps={{ ...input }}
            InputLabelProps={{ style: { color: "#333" } }}
          >
            {data.map((item) => (
              <MenuItem disabled={item.disabled} key={`option-${item.value}`} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      );
    }}
  </Field>
);

export default Select;
