import { AsyncAction } from "overmind";
import { LoginPayload } from "./types";

export const login: AsyncAction<LoginPayload> = async (
  {
    state,
    effects: {
      firebase: { auth },
    },
  },
  payload
) => {
  state.auth.logging = true;
  state.auth.loginError = false;

  try {
    const response = await auth().signInWithEmailAndPassword(payload.email, payload.password);
    state.auth.user = { id: response.user.uid };
  } catch {
    state.auth.loginError = true;
  } finally {
    state.auth.logging = false;
  }
};

export const getAuth: AsyncAction = async ({
  state,
  effects: {
    firebase: { auth },
  },
}) => {
  state.auth.logging = true;

  auth().onAuthStateChanged((user) => {
    state.auth.logging = false;
    state.auth.logged = Boolean(user);
    state.auth.user = user ? { id: user.uid } : null;
  });
};

export const logout: AsyncAction = async ({
  state,
  effects: {
    firebase: { auth },
  },
}) => {
  await auth().signOut();

  state.auth.logged = false;
};
